
// Fonts
@import url(https://fonts.googleapis.com/css?family=Raleway:300,400,600);

// Variables
@import "variables";

// Bootstrap
@import "node_modules/bootstrap-sass/assets/stylesheets/bootstrap";

.data-container{
	border: 1px solid #ccc;
	margin: 0px;
	padding: 12px;
	border-radius-right: 5px;
	border-right-radius: 5px;
	border-top-right-radius: 5px;
	border-bottom-right-radius: 5px;
}
img{
	max-width: 400px !important;
	max-height: 500px !important;
	margin-bottom: 5%;
	margin: 0px auto;
}
.input-group-addon{
	width: 150px !important;
}
.input-group-lg{
	width: 100%;
	margin-top: 20px;
}
.input-group-lg:last-child{
	margin-bottom: 20px;
}
.btn{
	margin-top: 5%;
	width: 125px;
}
@media (min-width: 768px)
{
	.modal-dialog {
	    width: 80% !important;
	    margin: 30px auto;
	}
}
.glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear;
}

@-webkit-keyframes spin2 {
    from { -webkit-transform: rotate(0deg);}
    to { -webkit-transform: rotate(360deg);}
}

@keyframes spin {
    from { transform: scale(1) rotate(0deg);}
    to { transform: scale(1) rotate(360deg);}
}
button > p{
	margin: 0px;
}

#sizing-addon1{
	background: rgb(55,152,206);
	color: white;
}

#ex1Slider .slider-selection {
	background: #BABABA;
}

.glyphicon{
	padding-right: 10px;
	color: rgb(55,152,206);
}
a:hover{
	text-decoration: none;
}

.buttonContainer{
	display: inline-block;
    margin: 0px auto;
    text-align: center;
    width: 100%;
}
.btn-danger .glyphicon{
	color: #fff !important;
}